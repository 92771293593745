<template>
     <b-modal size="xl"  hide-footer hide-header id="bv-loyalty-widget-modal">
  <div class="container">
    <div class="row">
      <!----<div class="col-md-4 offset-md-2 px-0">
        <div class="hero-img" :style="{ backgroundImage: `url('${currentBackground}')` }"></div>

      </div>-->
      <div class="col-md-12 px-0">
        <div class="dashboard">


          <b-overlay :show="processing" rounded="sm">
          <header class="profile">
            <div class="buttons">
              <button @click="back()"  class="back"><img :src="`${publicPath}/images/back.svg`" /></button>
              <button @click="close()"  class="close">
                <img  :src="`${publicPath}/images/close-black.svg`" />
              </button>
            </div>
            <div class="name-point">
              <div class="name">
                <b-avatar src="/img/gift_buttton_male.svg"></b-avatar>
                <span style="margin-left: 1.375rem">{{ userInfo.name }}</span>
                <p style="text-align:center; font-size:16px">Member No. {{ userInfo.member_number }}</p>
              </div>
              
            </div>
            <div class="points">
              <div><span class="txt-brand">{{ userInfo.points }} Active Points</span><br>

                <small class="txt-band-2">({{ userInfo.points_value }})</small><br>

                <span class="small gray">{{ userInfo.blocked_points }} Blocked Points</span><br>
              
              </div>
                
              <button  v-b-modal.redemption-modal class="btn btn-sec">Redeem Points</button>
                
              </div>
           <div class="level row hide">
             <div class="prof-level col-md-4">
               <img :src="`${publicPath}/images/badge.svg`" />
               BASIC LEVEL
             </div>
             <div v-if="showProgress" class="level-progress col-md-8">
               <ul class="progressbar">
        <li class="active">Basic</li>
        <li>Standard</li>
        <li>Premium</li>
      </ul>
             </div>

           </div>


          </header>

          <b-card-group deck v-if="!showOtp">
            <b-card title="" img-alt="Image" img-top tag="article" class="">
              <b-card-text>
                <b-tabs content-class="mt-3">
                  <b-tab @click="showTransferHistory=false"  title="Deals" active><p>
                      <b-list-group>
                        <div class="desc no-bd-bg">
                          <p>This reflects the promotions available for you to earn more points</p>
                          </div>
                        <b-list-group-item v-for="deal in deals" v-bind:key="deal.id">
                          
                          <div class="row">
                             <div class="col-2">
                                <b-icon-shop></b-icon-shop> 
                             </div>
                             <div class="col-6">
                          <h2 v-if="!deal.url">{{ deal.name }}</h2>
                          <a v-if="deal.url" target="_blank" :href="deal.url"><h2>{{ deal.name }}</h2></a>
                          <p v-html="deal.description"></p> 
                             </div>
<div class="col-4 earn">
                          +{{ deal.points }} Points
                          </div>
                          </div>
                        </b-list-group-item>
                        
                      </b-list-group>
                    </p></b-tab
                  >
                  <b-tab @click="showTransferHistory=false"  title="Redeemed"
                    ><p>
                     
                      <b-list-group>
                        <div class="desc no-bd-bg">
                          <p>Below is a summary of your redeemed Furaha Rewards loyalty points</p>
                          </div>
                        <b-list-group-item v-for="item in userInfo.redemptions" v-bind:key="item.id">
                          
                          <div class="row">
                             <div class="col-2">
                                <b-icon-shop></b-icon-shop> 
                             </div>
                             <div class="col-6">
                          <h2>{{  item.name  }}</h2>
                          <p>{{ item.description }}</p> 
                             </div>
<div class="col-4 earn">
                          -{{ item.points }} Points
                          </div>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                    </p></b-tab
                  >
                  <b-tab @click="showTransferHistory=false"  title="My Earnings">
                    <p>
                      <b-list-group>
                        <div class="desc no-bd-bg">
                          <p>Below shows the Furaha Reward loyalty points you have earned</p>
                          </div>

                          <div class="points-warning">
                            <img  :src="`${publicPath}/images/warning.svg`" /> Your earned points will expire in one year,from date of earning !
                          </div>
                        
                        <b-list-group-item v-for="item in userInfo.earnings" v-bind:key="item.id">
                          
                          <div class="row">
                             <div class="col-2">
                                <b-icon-shop></b-icon-shop> 
                             </div>
                             <div class="col-6">
                          <h2>{{ item.description }}</h2>
                             </div>
<div class="col-4 earn">
                          +{{item.points}} Points
                          </div>
                          </div>
                        </b-list-group-item>
                        
                      </b-list-group>
                    </p>
                  </b-tab>
                  <b-tab @click="showTransferHistory=false"  title="Ways to redeem" ><p>
                      <b-list-group>
                        <div class="desc no-bd-bg">
                          <p>This reflects the promotions available for you to redeem your points</p>
                          </div>
                        <b-list-group-item v-for="deal in waysToRedeem" v-bind:key="deal.id">
                          
                          <div class="row">
                             <div class="col-2">
                                <b-icon-shop></b-icon-shop> 
                             </div>
                             <div class="col-6">
                          <h2>{{ deal.name }}</h2>
                          <p v-html="deal.description"></p> 
                             </div>
<div class="col-4 earn">
                         
                          </div>
                          </div>
                        </b-list-group-item>
                        
                      </b-list-group>
                    </p></b-tab
                  >
                  <b-tab v-if="showTransfer" @click="showTransferHistory=false"  title="Transfer">
                    
                      <div v-if="!showTransferHistory">
                        <div class="row">
                      <div class="col-6">
                          <p>Enter the details below to transfer points</p>
                          </div>
                          <div class="col-6"><a @click="showTransferHistory=true" class="btn-white">View Transfer History</a></div>
                    </div>
                      <b-form class="transfer">
                        
                        <b-form-group
                          id="input-group-1"
                          label="Enter recipient phone number:"
                          label-for="input-1"
                        >
                          <b-form-input
                            id="input-1"
                            type="text"
                            placeholder=""
                            required

                          v-model="form.recipient"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                          id="input-group-2"
                          label="Amount of points to transfer"
                          label-for="input-2"
                        >
                          <b-form-input
                            id="input-2"
                            placeholder=""
                            required
                            v-model="form.points"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                          id="input-group-4"
                          v-slot="{ ariaDescribedby }"
                        >
                          <b-form-checkbox-group
                            id="checkboxes-4"
                            :aria-describedby="ariaDescribedby"
                          >
                            <b-form-checkbox required v-model="confirm" value="1"
                              >Are you sure you want to
                              transfer?</b-form-checkbox
                            >
                          </b-form-checkbox-group>
                        </b-form-group>

                        <b-button @click="onSubmit" variant="primary" class="w-100"
                          >Submit</b-button
                        >
                      </b-form>
                      </div>
                  </b-tab>
                </b-tabs>
              </b-card-text>
              <b-card-text class="transferhistory" v-if="showTransferHistory">
                
                      <div class="desc no-bd-bg mx-4">
                          <p>This reflects the points you have recently transfered</p>
                          </div>
                   
                <b-list-group-item v-for="transfer in userInfo.transfers" v-bind:key="transfer.points">
                          
                          <div class="row">
                             <div class="col-7">
                          <h2>{{  transfer.name }}</h2>
                          <p>{{ transfer.phone_number }}</p> 
                             </div>
<div class="col-5 earn">
                          <span class="txt-green">Sent - {{ transfer.number_of_points }} pts </span><br>
                          {{ transfer.created_at }}
                          </div>
                          </div>
                        </b-list-group-item>
              </b-card-text>
            </b-card>
          </b-card-group>

       <OtpForm v-if="showOtp" otpType="transfer_points"/>
       <b-modal  @ok="handleRedemption" id="redemption-modal" title="Redeem Points">
         <h3 class="popup" style="display:flex;justify-content: end;">Amounts Redeemable &nbsp;&nbsp;<span style="color: #B36B29;">  {{ userInfo.points_value }}</span></h3>
    <p class="my-4">
      
       <!-- <b-form-group
              id="input-group-1"
              label="Amount to redeem"
              label-for="input-1"
              description=""
            >
              <b-form-input
                id="input-1"
                v-model="pointsRedeem"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>-->
      <b-form-group v-if="showRedemptionReference"
              id="input-group-1"
              label="Reference"
              label-for="input-1"
              description=""
            >
              <b-form-input
                id="input-1"
                v-model="redemptionReference"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label="Amount"
              label-for="input-1"
              description=""
            >
              <b-form-input
                id="input-1"
                v-model="redemptionAmount"
                type="text"
                placeholder=""
                required
              ></b-form-input>
              <b-form-checkbox-group
                            id="checkboxes-4"
                            :aria-describedby="ariaDescribedby"
                          >
                            <b-form-checkbox required v-model="confirm" value="1"
                              >Are you sure you want to
                              redeem?</b-form-checkbox
                            >
                          </b-form-checkbox-group>
            </b-form-group>
    </p>
  </b-modal>
       <a  v-if="showOtp" @click="showOtp=false" >close</a>


  </b-overlay>
        </div>
        <footer>
          <p style="display:none">
            <img :src="`${publicPath}/images/footer-star.svg`" />We reward our guests with a
            smile
          </p>
        </footer>
      </div>
    </div>

  </div>
  </b-modal>
</template>
  
  <script>
import { mapMutations } from 'vuex';
import LoyaltyService from '../api/LoyaltyService';
import OtpForm from '../components/Otp'
export default {
  components: {
    OtpForm,
  },
  data() {
    return {
      showProgress: false,
      processing: false,
      showOtp: true,
      showTransfer:false,
      showTransferHistory: false,
      customerName: "John Doe",
      customerPoints: 0,
      showRedemptionReference: true,
      redemptionReference: '',
      redemptionAmount: '',
      "publicPath": process.env.VUE_APP_ASSETS_URL,
      confirm: 0,
      form: {
        "sender":'',
        "recipient":'',
        "points":''
      },
      backgrounds: [
        'images/furaha_female.svg',
        'images/furaha_male.svg',
        
        // Add more background image paths as needed
      ],
      currentBackground: '',
      deals: [],
      waysToRedeem: [],
    };
  },
  methods: {
    changeBackground() {
      // Select a random background
      this.currentBackground =  this.publicPath+"/"+this.backgrounds[Math.floor(Math.random() * this.backgrounds.length)];
    },
    ...mapMutations(['setCurrentRequest','isLoggedIn']),
    onSubmit(e) {
        this.processing = true;
        e.preventDefault();
        let requestData = this.form;
        if(this.confirm){
        this.confirm = false;//reset
        requestData['sender'] = this.currentRequest['phone_number']
        LoyaltyService.initiateTransferOfPoints(requestData).then(response => {
          this.processing = false;
            if(response.status == 200){
                
                let currentRequest  = this.currentRequest;
                console.log(response)
                currentRequest['request_id'] = '';
                currentRequest['otp_id'] = response.data.otp_id;
                currentRequest['transfer_request_id'] = response.data.transfer_request_id;
                this.resetTransferForm();
                this.$store.commit('setCurrentRequest', currentRequest);
                this.showOtp = true;
            }else{
              this.showOtp = false;
              this.resetTransferForm();
              this.$fire({
                title: 'Bad Request',
                text: response.message,
                type: 'warning',
                timer: 10000
              }).then(r => {
                console.log(r.value);
              });
            }
        }).catch(error =>{
          console.log(error)
          this.showOtp = false;
          this.resetTransferForm();
          this.$fire({
                title: 'Server error',
                text: error,
                type: 'error',
                timer: 100000
              }).then(r => {
                console.log(r.value);
              });
      })
    }else{
      this.processing = false;
    }
      },
     fetchDeals(){
      LoyaltyService.getDeals().then(response => {
            if(response.status == 200){
                this.deals = response.data.data;
            }else{
              this.$fire({
                title: 'Bad Request',
                text: response.message,
                type: 'warning',
                timer: 10000
              }).then(r => {
                console.log(r.value);
              });
            }
        }).catch(error =>{
          console.log(error)
          this.$fire({
                title: 'Server error',
                text: error,
                type: 'error',
                timer: 100000
              }).then(r => {
                console.log(r.value);
              });
      })
     },
     fetchWaysToRedeem(){
      LoyaltyService.getWaysToRedeem().then(response => {
            if(response.status == 200){
                this.waysToRedeem = response.data.data;
            }else{
              this.$fire({
                title: 'Bad Request',
                text: response.message,
                type: 'warning',
                timer: 10000
              }).then(r => {
                console.log(r.value);
              });
            }
        }).catch(error =>{
          console.log(error)
          this.$fire({
                title: 'Server error',
                text: error,
                type: 'error',
                timer: 100000
              }).then(r => {
                console.log(r.value);
              });
      })
     },
     fetchuserInfo(){
      LoyaltyService.getUserInfo(this.userInfo.phone_number).then(response => {
            if(response.status == 200){
              this.$store.commit('setUserInfo', response);
            }
        }).catch(error =>{
          console.log(error)
          this.$fire({
                title: 'Server error',
                text: error,
                type: 'error',
                timer: 100000
              }).then(r => {
                console.log(r.value);
              });
      })
     },
     handleOtpComplete(value){
        console.log('Transfered done');
        console.log(value);
        this.showOtp = false;
     },
     resetTransferForm(){
        this.form.sender = "";
        this.form.recipient = "";
        this.form.points = "";
     },
     back(){
        this.$router.push({ name: 'home'})
     },
     close(){
        this.$store.commit('setUserInfo', null);
        this.$router.push({ name: 'home'})
     },
     ariaDescribedby(){

   },
     handleRedemption() {
      this.processing = true;
      var requestData = {
                  'phone_number':this.userInfo.phone_number,
                  'amount':this.redemptionAmount,
                  'reference_number':this.redemptionReference,
                  'country_code':'KE',
                  'cashier_name':'Widget',
                  'branch':'web'
                  }
          if(this.confirm){
            this.confirm = false;
            LoyaltyService.redeem(requestData).then(response => {
                this.processing = false;
                  if(response.status == 200){
                    this.$store.commit('setUserInfo', response.user_info);
                    this.$fire({
                        title: 'Success',
                        text: response.message,
                        type: 'success',
                        timer: 10000
                    }).then(r => {
                        console.log(r)
                    });
                  }else{
                    this.$fire({
                      title: 'Bad Request',
                      text: response.message,
                      type: 'warning',
                      timer: 10000
                    }).then(r => {
                      console.log(r.value);
                    });
                  }
              }).catch(error =>{
                console.log(error)
                this.$fire({
                      title: 'Server error',
                      text: error,
                      type: 'error',
                      timer: 100000
                    }).then(r => {
                      console.log(r.value);
                    });
            })
          }else{
              this.processing = false;
              this.$fire({
                        title: 'Info',
                        text: 'You have to click confirm checkbox before submitting',
                        type: 'warning',
                        timer: 10000
                      }).then(r => {
                        console.log(r.value);
                      });
            }
    }
  },
  mounted(){
    this.$bvModal.show('bv-loyalty-widget-modal')
     this.fetchDeals();
     this.fetchWaysToRedeem();
     this.fetchuserInfo();
     this.showOtp = false;
    this.changeBackground();
    setInterval(this.changeBackground,7 * 24 * 60 * 60 * 1000); // Change every week
    //setInterval(this.changeBackground, 10 * 60 * 1000);  //Change every 10 minutes
    
  
  },
  created(){
    this.$root.$on('otpCompleted', (value) => {
      console.log(value);
      this.showOtp = false
    })
  },

  computed: {
      currentRequest: function () {
        return this.$store.state.currentRequest;
      },
      userInfo: function () {
        return this.$store.state.userInfo;
      }
    }
}
</script>